import { api } from '@/const/axiosApi'

export default {
  namespaced: true,

  state: {
    mediaResources: [],
    mediaResourcesMeta: {},
    maxiBanners: [],
    maxiBannersMeta: {},
    miniBanners: [],
    miniBannersMeta: {},
    mediaResource: {},
  },

  actions: {
    async loadMediaResources(
      { commit, dispatch },
      { companyId, page = 1, limit = 10, sort, params, createdAt, updatedAt, ...rest }
    ) {
      const url = `/api/v1/companies/${companyId}/media-resource`
      let query = { page, limit }
      const optionalParams = { sort, params, createdAt, updatedAt, ...rest }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key ?? !!value)),
      }
      try {
        const data = await api.get(url, { params: query })
        commit('SET_MEDIA_RESOURCES', data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async loadBanners({ commit, dispatch }, { companyId, page = 1, limit = 10, sort, params, createdAt, updatedAt }) {
      const url = `/api/v1/companies/${companyId}/media-resource`
      let query = { page, limit }
      const optionalParams = { sort, params, createdAt, updatedAt }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key ?? !!value)),
      }
      try {
        const data = await api.get(url, { params: query })
        params.banner_type === 2 ? commit('SET_MINI_BANNERS', data) : commit('SET_MAXI_BANNERS', data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async createMediaResource({ commit, dispatch }, { companyId, ...payload }) {
      const url = `/api/v1/companies/${companyId}/media-resource`
      try {
        const data = await api.post(url, payload.newMediaResource)
        await dispatch('logs/displaySuccessAlert', { message: 'Медіа-ресурс створено успішно!' }, { root: true })
        commit('SET_MEDIA_RESOURCE', data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async loadSelectedMediaResource({ commit, dispatch }, { companyId, resourceId }) {
      const url = `/api/v1/companies/${companyId}/media-resource/${resourceId}`
      try {
        const { data } = await api.get(url)
        commit('SET_MEDIA_RESOURCE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async updateSelectedMediaResource({ commit, dispatch }, { companyId, resourceId, ...payload }) {
      console.log(payload)
      try {
        const url = `/api/v1/companies/${companyId}/media-resource/${resourceId}`
        const { data } = await api.put(url, payload.updatedMediaResource)
        await dispatch('logs/displaySuccessAlert', { message: 'Медіа-ресурс оновлено успішно!' }, { root: true })
        commit('SET_MEDIA_RESOURCE', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async deleteSelectedMediaResource({ dispatch }, { companyId, resourceId }) {
      const url = `/api/v1/companies/${companyId}/media-resource/${resourceId}`
      try {
        await api.delete(url)
        await dispatch('logs/displaySuccessAlert', { message: 'Медіа-ресурс видалено!' }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },

  mutations: {
    SET_MEDIA_RESOURCES(state, val) {
      state.mediaResources = val.data.data
      state.mediaResourcesMeta = val.data.meta
    },

    SET_MINI_BANNERS(state, val) {
      state.miniBanners = val.data.data
      state.miniBannersMeta = val.data.meta
    },

    SET_MAXI_BANNERS(state, val) {
      state.maxiBanners = val.data.data
      state.maxiBannersMeta = val.data.meta
    },

    SET_MEDIA_RESOURCE(state, val) {
      state.mediaResource = val
    },
  },

  getters: {
    getMediaResourcesCount(state) {
      return state.mediaResourcesMeta.total_count
    },
  },
}
